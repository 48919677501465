import React from 'react';
// import logo from '../../logo.svg';
// import { Counter } from '../../features/counter/Counter';
import styles from './App.module.scss';
import Graph from '../Graph/Graph';
import FlyingObjects from '../FlyingObjects/FlyingObjects';
import Pong from '../Pong/Pong';
import Sea from '../Sea/Sea';
import MoveAway from '../MoveAway/MoveAway';

import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const history = createBrowserHistory();

Sentry.init({
  dsn:
    'https://1cf2fdcda32146ea9c7ed326e73b29e5@o374339.ingest.sentry.io/5192191',
  release: 'kpi@' + process.env.npm_package_version,
  tracesSampleRate: 1,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
});

function App() {
  return (
    <div className={styles.App}>
      <header className={styles.AppHeader}>
        <Router history={history}>
          <div>
            <nav>
              <ul className={styles.navItems}>
                <li>
                  <Link to="/lab-10">Lab-10</Link>
                </li>
                <li>
                  <Link to="/lab-11">Lab-11</Link>
                </li>
                <li>
                  <Link to="/lab-12">Lab-12</Link>
                </li>
                <li>
                  <Link to="/lab-13">Lab-13</Link>
                </li>
                <li>
                  <Link to="/lab-13+">Lab-13+</Link>
                </li>
              </ul>
            </nav>

            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
            <Switch>
              {/*// Lab-10 on Default route */}
              <Route exact path="/">
                <Graph />
              </Route>
              {/*// Lab-10 Component */}
              <Route path="/lab-10">
                <Graph />
              </Route>
              {/*// Lab-11 Component */}
              <Route path="/lab-11">
                <FlyingObjects />
              </Route>
              {/*// Lab-12 Component */}
              <Route path="/lab-12">
                <Pong />
              </Route>
              {/*// Lab-13 Component */}
              <Route path="/lab-13">
                <MoveAway />
              </Route>
              {/*// Lab-14 Component */}
              <Route path="/lab-13+">
                <Sea />
              </Route>
            </Switch>
          </div>
        </Router>
      </header>
    </div>
  );
}

export default Sentry.withProfiler(App);
