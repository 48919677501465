import React, { Component } from 'react';
import * as d3 from 'd3';
import styles from './FlyingObjects.module.scss';

const initialState = {};

class FlyingObjects extends Component {
  constructor(props) {
    super(props);
    this.canvas = React.createRef();
    this.state = initialState;
    this.draw = this.draw.bind(this);
  }

  componentDidMount() {
    this.draw(50);
  }

  draw(number) {
    // SVG Area Dimensions
    const height = 500;
    const width = 960;
    const colors = [
      '#01d1b2',
      '#f44336',
      '#e91e63',
      '#9C27B0',
      '#673AB7',
      '#3F51B5',
      '#2196F3',
      '#03A9F4',
      '#00BCD4',
      '#009688',
      '#4CAF50',
      '#8BC34A',
      '#CDDC39',
      '#CDDC39',
      '#FFC107',
      '#FF9800',
      '#FF5722',
      // '#795548',
      // '#9E9E9E',
      // '#9E9E9E',
    ];

    // SVG Area
    const svg = d3
      .select(this.canvas.current)
      .append('svg')
      .attr('id', 'area')
      .attr('class', styles.area)
      .attr('height', height)
      .attr('width', width);

    const drawStar = () => {
      const radius = 32;
      let rotationAngle = 0;
      let positionX = width / 2 - radius / 2;
      let positionY = height / 2 - radius / 2;
      const starGroup = svg.append('g');
      const star = starGroup.append('svg');

      star
        // .append('svg')
        .attr('height', radius)
        .attr('width', radius)
        .attr('viewBox', `${0} ${0} ${64} ${64}`)
        .attr('x', positionX)
        .attr('y', positionY)
        .append('g')
        .append('path')
        .attr(
          'd',
          'M32 2L39.1844 24.1115H62.4338L43.6247 37.7771L50.8091 59.8885L32 46.2229L13.1909 59.8885L20.3753 37.7771L1.56619 24.1115H24.8156L32 2Z',
        )
        .attr('fill', colors[Math.floor(Math.random() * colors.length)]);

      // Initial direction for the objects
      const scale = d3.scaleLinear().domain([0, 1]).range([-1, 1]); // Using D3 linear scale to get random values from -1 to 1;
      let vector = {
        x: scale(Math.random()),
        y: scale(Math.random()),
      };
      const speed = 5; // Speed factor

      const collisionCheck = () => {
        const boundaryTop = 0;
        const boundaryBottom = height - radius;
        const boundaryLeft = 0;
        const boundaryRight = width - radius;

        if (positionX <= boundaryLeft) {
          vector.x = -vector.x;
          console.log('Hit left boundary');
        }
        if (positionX >= boundaryRight) {
          vector.x = -vector.x;
          console.log('Hit right boundary');
        }
        if (positionY <= boundaryTop) {
          vector.y = -vector.y;
          console.log('Hit top boundary');
        }
        if (positionY >= boundaryBottom) {
          vector.y = -vector.y;
          console.log('Hot bottom boundary');
        }
      };

      const move = () => {
        collisionCheck(); // Check if we hit the wall
        const rotationSpeed = 3;

        // Moving the Objects with random velocities
        star
          .attr('x', (positionX += vector.x * speed))
          .attr('y', (positionY += vector.y * speed));

        // Rotating Objects - Grouped to Fix issue for SVG 1.1
        starGroup.attr(
          'transform',
          `rotate(${(rotationAngle += rotationSpeed)},${
            positionX + radius / 2
          },${positionY + radius / 2})`,
        );
      };

      d3.timer(move, 0); // Using D3 queue to manage the animations
    };

    for (let i = 0; i < number; i++) {
      drawStar();
    }
  }

  render() {
    return (
      <div>
        <h1 className={styles.title}>KPI-PB-Lab-11</h1>
        <div ref={this.canvas}></div>
      </div>
    );
  }
}

export default FlyingObjects;
