import React, { Component } from 'react';
import * as d3 from 'd3';
import styles from './Graph.module.scss';

const initialState = {
  title: '',
  dataset: [],
  selected: '',
};

class Graph extends Component {
  constructor(props) {
    super(props);
    this.canvas = React.createRef();
    this.state = initialState;

    this.drawGraph = this.drawGraph.bind(this);
    this.generateDataSin = this.generateDataSin.bind(this);
    this.generateDataCos = this.generateDataCos.bind(this);
    this.generateDataTan = this.generateDataTan.bind(this);
    this.generateDataCot = this.generateDataCot.bind(this);
  }

  componentDidMount() {
    this.generateDataSin();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.deleteGraph();
    this.drawGraph();
  }

  generateDataSin(lower = -360, upper = 360) {
    const arr = [];
    for (lower; lower <= upper; lower++) {
      arr.push([lower, Math.sin((lower * Math.PI) / 180)]);
    }
    this.setState({
      title: 'Sine: y=sin(x)',
      dataset: [...arr],
      selected: 'sin',
    });
  }

  generateDataCos(lower = -360, upper = 360) {
    const arr = [];
    for (lower; lower <= upper; lower++) {
      arr.push([lower, Math.cos((lower * Math.PI) / 180)]);
    }
    this.setState({
      title: 'Cosine: y=cos(x)',
      dataset: [...arr],
      selected: 'cos',
    });
  }

  generateDataTan(lower = -360, upper = 360) {
    const arr = [];
    // Ignoring asymptotic value
    const valuesToIgnore = [-270, -90, 90, 270];
    for (lower; lower <= upper; lower++) {
      if (!valuesToIgnore.includes(lower)) {
        const value = Math.tan(lower * (Math.PI / 180));
        arr.push([lower, value]);
      }
    }
    this.setState({
      title: 'Tangent: y=tan(x)',
      dataset: [...arr],
      selected: 'tan',
    });
  }

  generateDataCot(lower = -360, upper = 360) {
    const arr = [];
    // Ignoring asymptotic value
    const valuesToIgnore = [-360, -180, 0, 180, 360];
    for (lower; lower <= upper; lower++) {
      if (!valuesToIgnore.includes(lower)) {
        const value = 1 / Math.tan(lower * (Math.PI / 180));
        arr.push([lower, value]);
      }
    }
    this.setState({
      title: 'Cotangent: y=1/tan(x)',
      dataset: [...arr],
      selected: 'cot',
    });
  }

  deleteGraph() {
    d3.select('#graph').remove();
  }

  drawGraph() {
    const height = 500;
    const width = 960;

    const padding = 60;

    // Scales
    const xScale = d3
      .scaleLinear()
      .domain([
        d3.min(this.state.dataset, (d) => d[0]),
        d3.max(this.state.dataset, (d) => d[0]),
      ])
      .range([padding, width - padding]);

    const yScale = d3
      .scaleLinear()
      .domain([
        d3.min(this.state.dataset, (d) => d[1]),
        // -1,
        d3.max(this.state.dataset, (d) => d[1]),
        // 1,
      ])
      .range([height - padding, padding]);

    // Axis
    const xAxis = d3.axisBottom(xScale).ticks(45);
    const yAxis = d3.axisLeft(yScale);

    // Line generator
    const line = d3
      .line()
      .x((d) => xScale(d[0]))
      .y((d) => yScale(d[1]))
      .curve(d3.curveMonotoneX);

    const svg = d3
      .select(this.canvas.current)
      .append('svg')
      .attr('id', 'graph')
      .attr('class', styles.graph)
      .attr('height', height)
      .attr('width', width);

    svg
      .append('path')
      .datum(this.state.dataset) // Binding data to the line
      .attr('class', styles.line)
      .attr('d', line); // Calling the line generator

    // Adding Axis
    svg
      .append('g')
      .attr('transform', 'translate(0,' + height / 2 + ')')
      .call(xAxis);

    svg
      .append('g')
      .attr('transform', `translate(${width / 2},0)`)
      .call(yAxis);

    // Adding Title
    svg
      .append('text')
      .attr('x', width / 2)
      .attr('y', padding / 2)
      .attr('text-anchor', 'middle')
      .style('font-size', '24px')
      .style('font-weight', '700')
      .text(this.state.title);
  }

  render() {
    const selected = this.state.selected;
    return (
      <div>
        <h1 className={styles.title}>KPI-PB-Lab-10</h1>
        <div className={styles.buttons}>
          <button
            className={
              selected === 'sin' ? 'button is-primary' : 'button is-dark'
            }
            onClick={() => this.generateDataSin()}
          >
            Sin
          </button>
          <button
            className={
              selected === 'cos' ? 'button is-primary' : 'button is-dark'
            }
            onClick={() => this.generateDataCos()}
          >
            Cos
          </button>
          <button
            className={
              selected === 'tan' ? 'button is-primary' : 'button is-dark'
            }
            onClick={() => this.generateDataTan()}
          >
            Tan
          </button>
          <button
            className={
              selected === 'cot' ? 'button is-primary' : 'button is-dark'
            }
            onClick={() => this.generateDataCot()}
          >
            Cot
          </button>
        </div>
        <div ref={this.canvas}></div>
      </div>
    );
  }
}

export default Graph;
