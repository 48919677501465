import React, { Component } from 'react';
import * as d3 from 'd3';
import styles from './MoveAway.module.scss';

/* Using D3 Force Layout https://www.d3indepth.com/force-layout/ */

const initialState = {};

class MoveAway extends Component {
  constructor(props) {
    super(props);
    this.canvas = React.createRef();
    this.state = initialState;
    this.draw = this.draw.bind(this);
  }

  componentDidMount() {
    this.draw(500, 1000);
  }

  draw(height, width) {
    // Colors
    const colors = [
      '#01d1b2',
      '#f44336',
      '#e91e63',
      '#9C27B0',
      '#673AB7',
      '#3F51B5',
      '#2196F3',
      '#03A9F4',
      '#00BCD4',
      '#009688',
      '#4CAF50',
      '#8BC34A',
      '#CDDC39',
      '#CDDC39',
      '#FFC107',
      '#FF9800',
      '#FF5722',
      // '#795548',
      // '#9E9E9E',
      // '#9E9E9E',
    ];

    // Helper Functions

    // Function to handle advancement/changes in the simulation. Updating circle positions
    const ticked = () => {
      svg
        .selectAll('circle')
        .attr('cx', (d) => {
          return (d.x = Math.max(d.radius, Math.min(width - d.radius, d.x)));
        })
        .attr('cy', (d) => {
          return (d.y = Math.max(d.radius, Math.min(height - d.radius, d.y)));
        });
    };

    // SVG Area
    const svg = d3
      .select(this.canvas.current)
      .append('svg')
      .attr('id', 'area')
      .attr('class', styles.area)
      .attr('height', height)
      .attr('width', width);

    let nodes = d3.range(1000).map(() => {
      return { radius: 5 };
    });

    const root = nodes[0]; // Using first circle to manipulate (push) other circles
    root.radius = 10;

    // Setting up forceSimulation simulation
    const forceSimulation = d3
      .forceSimulation(nodes) // Selecting data points
      .force('center', d3.forceCenter(width / 2, height / 2)) // Force nodes to appear in center
      .force(
        'charge',
        d3.forceManyBody().strength((d, i) => {
          return i ? 0 : 50; // Strength of attraction, positive value attracts, negative repels.
        }),
      )
      .force(
        'collision',
        d3.forceCollide().radius((d) => {
          return d.radius;
        }),
      ) // Handle collision based on node radius, so that objects don't overlap
      .on('tick', ticked);

    svg
      .selectAll('circle')
      .data(nodes.slice(1))
      .enter()
      .append('circle')
      .attr('r', (d) => {
        return d.radius;
      })
      .style('fill', () => {
        return colors[Math.floor(Math.random() * colors.length)];
      });

    // Updating first nodes position on mousemove event
    svg.on('mousemove', function () {
      let p1 = d3.mouse(this);
      root.fx = p1[0];
      root.fy = p1[1];

      forceSimulation.alpha(0.9).restart();
    });
    svg.on('mousedown', () => {
      root.radius = 250;
      forceSimulation.force(
        'collision',
        d3.forceCollide().radius((d) => {
          return d.radius;
        }),
      );
    });
    svg.on('mouseup', () => {
      root.radius = 10;
      forceSimulation.force(
        'collision',
        d3.forceCollide().radius((d) => {
          return d.radius;
        }),
      );
    });
  }

  render() {
    return (
      <div>
        <h1 className={styles.title}>KPI-PB-Lab-13</h1>
        <p>Click around, see some movement</p>
        <div ref={this.canvas}></div>
      </div>
    );
  }
}

export default MoveAway;
