import React, { Component } from 'react';
import * as d3 from 'd3';
import styles from './Pong.module.scss';

const initialState = {
  paddle: {
    height: 16,
    width: 200,
    positionX: 400,
    positionY: 450,
  },
};

class Pong extends Component {
  constructor(props) {
    super(props);
    this.canvas = React.createRef();
    this.state = initialState;
    this.draw = this.draw.bind(this);
  }

  componentDidMount() {
    this.draw(500, 1000, 16);
  }

  draw(height, width, numberOfBalls) {
    // Colors
    const colors = [
      '#01d1b2',
      '#f44336',
      '#e91e63',
      '#9C27B0',
      '#673AB7',
      '#3F51B5',
      '#2196F3',
      '#03A9F4',
      '#00BCD4',
      '#009688',
      '#4CAF50',
      '#8BC34A',
      '#CDDC39',
      '#CDDC39',
      '#FFC107',
      '#FF9800',
      '#FF5722',
      // '#795548',
      // '#9E9E9E',
      // '#9E9E9E',
    ];

    // SVG Area
    const svg = d3
      .select(this.canvas.current)
      .append('svg')
      .attr('id', 'area')
      .attr('class', styles.area)
      .attr('height', height)
      .attr('width', width);

    // Draw Paddle Method
    const drawPaddle = () => {
      const speed = 25;
      const paddle = svg
        .append('rect')
        .attr('height', this.state.paddle.height)
        .attr('width', this.state.paddle.width)
        .attr('x', this.state.paddle.positionX)
        .attr('y', this.state.paddle.positionY)
        .attr('fill', 'white');

      const move = (speed) => {
        const boundaryLeft = 0;
        const boundaryRight = width - this.state.paddle.width;

        const currentPositionX = this.state.paddle.positionX;

        if (
          currentPositionX >= boundaryLeft &&
          currentPositionX <= boundaryRight
        ) {
          // Set speed to zero if the paddle is at the top or bottom border
          if (
            (currentPositionX === boundaryLeft && Math.sign(speed) === -1) ||
            (currentPositionX === boundaryRight && Math.sign(speed) === 1)
          ) {
            speed = 0;
          }
          this.setState({
            paddle: {
              height: this.state.paddle.height,
              width: this.state.paddle.width,
              positionX: this.state.paddle.positionX + speed,
              positionY: this.state.paddle.positionY,
            },
          });
        }

        // Transition effect for paddle movement
        paddle.transition().duration(50).attr('x', this.state.paddle.positionX);
      };

      // Event Listeners for keys
      document.addEventListener('keydown', (event) => {
        if (event.code === 'KeyA' || event.code === 'ArrowLeft') {
          // console.log('W is pressed');
          move(-speed);
        }
        if (event.code === 'KeyD' || event.code === 'ArrowRight') {
          move(speed);
          // console.log('S is pressed');
        }
      });
    };

    // Draw Ball Method
    const drawBall = () => {
      const radius = 10;
      let positionX = width / 2;
      let positionY = height / 2;
      const ball = svg
        .append('circle')
        .attr('r', radius)
        .attr('cx', positionX)
        .attr('cy', positionY)
        .attr('fill', colors[Math.floor(Math.random() * colors.length)]);

      // Initial direction for the objects
      const scale = d3.scaleLinear().domain([0, 1]).range([-1, 1]); // Using D3 linear scale to get random values from -1 to 1;
      let vector = {
        x: scale(Math.random()),
        y: scale(Math.random()),
      };
      const speed = 5; // Speed factor

      const wallCollisionCheck = () => {
        const boundaryTop = 0 + radius;
        const boundaryBottom = height - radius;
        const boundaryLeft = 0 + radius;
        const boundaryRight = width - radius;

        if (positionX <= boundaryLeft) {
          vector.x = -vector.x;
          // console.log('Hit left boundary');
        }
        if (positionX >= boundaryRight) {
          vector.x = -vector.x;
          // console.log('Hit right boundary');
        }
        if (positionY <= boundaryTop) {
          vector.y = -vector.y;
          // console.log('Hit top boundary');
        }
        if (positionY >= boundaryBottom + radius * 2) {
          // vector.y = -vector.y;
          ball.remove();
          // console.log('Hot bottom boundary');
        }
      };

      const paddleCollisionCheck = () => {
        // Boundaries for Right side of paddle
        const boundaryRight =
          this.state.paddle.positionX + this.state.paddle.width + radius;
        const boundaryLeft = this.state.paddle.positionX - radius;
        const boundaryTop = this.state.paddle.positionY - radius;
        const boundaryBottom =
          this.state.paddle.positionY + this.state.paddle.height + radius;

        // Check for ball hitting the paddle on the right
        if (
          positionX <= boundaryRight &&
          positionY >= boundaryTop &&
          positionY <= boundaryBottom
        ) {
          vector.x = -vector.x;
          // console.log('Boom paddle hit on the right');
        }

        // Check for ball hitting the paddle on the left
        if (
          positionX <= boundaryLeft &&
          positionY >= boundaryTop &&
          positionY <= boundaryBottom
        ) {
          vector.x = -vector.x;
          // console.log('Boom paddle hit on the left');
        }

        // Check for ball hitting the paddle on the Top
        if (
          positionX <= boundaryRight &&
          positionX >= boundaryLeft &&
          positionY >= boundaryTop &&
          positionY <= boundaryTop + radius // Radius Offset to handle hitting at the top
        ) {
          vector.y = -vector.y;
          // console.log('Boom paddle hit on the Top');
        }

        // Check for ball hitting the paddle on the Bottom
        if (
          positionX <= boundaryRight &&
          positionX >= boundaryLeft &&
          positionY <= boundaryBottom &&
          positionY >= boundaryBottom - radius // Radius Offset to handle hitting at the bottom
        ) {
          vector.y = -vector.y;
          // console.log('Boom paddle hit on the Bottom');
        }
      };

      const move = () => {
        wallCollisionCheck(); // Check if ball hit the wall
        paddleCollisionCheck(); // Check if ball hit the paddle
        // Moving the Objects with random velocities
        ball
          .attr('cx', (positionX += vector.x * speed))
          .attr('cy', (positionY += vector.y * speed));
      };

      d3.timer(move, 0); // Using D3 queue to manage the animations
    };

    for (let i = 0; i < numberOfBalls; i++) {
      drawBall();
    }

    drawPaddle();
  }

  render() {
    return (
      <div>
        <h1 className={styles.title}>KPI-PB-Lab-12</h1>
        <p>Move with A,D or Arrow Keys</p>
        <div ref={this.canvas}></div>
      </div>
    );
  }
}

export default Pong;
